<template>
  <!-- 巡检员管理 ————> 查看异常处理 -->
  <div class="box-card" style="text-align: left">
    <!-- 头部 -->
    <div class="box-card">
      <div class="sign-content" style="text-align: left">
        <div class="title">异常说明</div>
        <el-divider></el-divider>
        <el-form label-position="right" label-width="180px" :model="detailsList">
          <el-row>
            <el-col :span="17">
              <el-form-item label="停车场：">{{
                detailsList.parking_name
              }}</el-form-item>
              <el-form-item label="车位编号：">{{
                detailsList.parking_space_name
              }}</el-form-item>
              <el-form-item label="详情说明：">{{
                detailsList.detail
              }}</el-form-item>
            </el-col>
            <el-col :span="7">
                <el-carousel :autoplay="false" v-if="formatImage(detailsList.image).length > 1">
                  <el-carousel-item v-for="(item, index) in formatImage(detailsList.image)" :key="index">
                    <el-image class="in-out-image" :src="item" :preview-src-list="formatImage(detailsList.image)"
                      :z-index="9000" fit="cover">
                      <div slot="error" class="image-slot">
                        <i class="iconfont lebo-picerr"></i>
                        <span>加载失败</span>
                      </div>
                    </el-image>
                  </el-carousel-item>
                </el-carousel>
                <div style=" height: 300px" v-else-if="formatImage(detailsList.image).length === 1">
                  <el-image class="in-out-image" fit="cover" v-for="(item, index) in formatImage(detailsList.image)"
                    :key="index" :src="item" :preview-src-list="formatImage(detailsList.image)" :z-index="9000">
                    <div slot="error" class="image-slot">
                      <i class="iconfont lebo-picerr"></i>
                      <span>加载失败</span>
                    </div>
                  </el-image>
                </div>
                <div class="maskBox">异常图像</div>
            </el-col>
          </el-row>
        </el-form>
        <div class="title">处理人</div>
        <el-divider></el-divider>
        <el-form label-position="right" label-width="180px" :model="detailsList">
          <el-form-item label="巡检人员：">{{
            detailsList.handler
          }}</el-form-item>
          <el-form-item label="手机号：">{{
            detailsList.phone ? detailsList.phone : "/"
          }}</el-form-item>
          <el-form-item label="处理方式：">
            {{
              getWayName("TreatmentMethod", detailsList.treatment_method)
            }}</el-form-item>
          <el-form-item label="处理时间：">
            {{ $moment(detailsList.create_time).format("YYYY-MM-DD HH:mm:ss") }}
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { getAbnormalHandleDetail } from '@/api/administrative'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      detailsList: {}
    }
  },
  created () {
    this.fnGetAbnormalHandleDetail()
  },
  computed: {
    ...mapState(['parkingList']),
    ...mapGetters(['getOptions', 'getWayName'])
  },
  mounted () { },
  methods: {
    ...mapMutations(['setQueryStatus']),
    async fnGetAbnormalHandleDetail () {
      const res = await getAbnormalHandleDetail({
        _id: this.id
      })
      // console.log(res);
      if (res && res.Code === 200) {
        this.detailsList = res.Data
      }
    },
    // 返回签到打卡页面
    goBack () {
      this.setQueryStatus(2)
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="less">
.title {
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

.sign-content {
  max-height: 650px;
  overflow-y: auto;
  .maskBox {
    width: 300px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: white;
    text-align: center;
    background-color: rgba(0, 0, 0, .5);
    // margin-top: -7px;
  }
}

.sign-content .el-form-item {
  margin-bottom: 0px;
}

.map {
  width: 800px;
  height: 500px;
}

/deep/.image-slot {
  width: 300px;
  height: 300px;
  display: flex  !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f5f7fa;
  color: #999;
  .iconfont{
    font-size: 36px;
  }
}

.el-carousel {
  width: 300px;
  height: 300px;

  /deep/ .el-carousel__container {
    position: relative;
    height: 300px;
  }

  /deep/ .el-carousel__button {
    background-color: #027dfc !important;
  }

  /deep/ .el-carousel__indicator--horizontal {
    padding: 4px 4px;
  }
}

.in-out-image {
  width: 300px;
  height: 100%;
}
</style>
