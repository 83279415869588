<template>
  <!-- 车辆异常处理 -->
  <div class="box-card" style="text-align: left;margin-top: 20px;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm" ref="queryFormRef">
      <el-form-item label="处理人：">
        <el-input v-model="queryForm.handler" @keyup.enter.native="onSubmit()"
          @input="(e) => (queryForm.handler = validSpace(e))" placeholder="请输入处理人"></el-input>
      </el-form-item>
      <el-form-item label="手机号：">
        <el-input v-model="queryForm.phone" @keyup.enter.native="onSubmit()"
          @input="(e) => (queryForm.phone = validSpace(e))" placeholder="请输入手机号" maxlength="11"></el-input>
      </el-form-item>
      <el-form-item label="停车场：">
        <el-select popper-class="my-select" v-model="queryForm.parking_id" filterable clearable default-first-option
          placeholder="请选择停车场" @change="onSubmit">
          <el-option v-for="item in parkingList" :key="item.parking_id" :label="item.parking_name"
            :value="item.parking_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="车牌号：">
        <el-input v-model="queryForm.plate_number" @keyup.enter.native="onSubmit()"
          @input="(e) => (queryForm.plate_number = validPlatetoUpperCase(e))" placeholder="请输入车牌号"
          maxlength="8"></el-input>
      </el-form-item>
      <el-form-item label="处理方式：">
        <el-select popper-class="my-select" v-model="queryForm.treatment_method" clearable placeholder="请选择处理方式"
          @clear="queryForm.treatment_method = null" @change="onSubmit()">
          <el-option v-for="item in getOptions('TreatmentMethod', true)" :key="item.value" :label="item.name"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="车辆异常处理">
      <template slot="heard-right">
        <div class="right">
          <!-- <el-button  size="small" type="danger"  @click="batchDel" >删除</lbButton> -->
          <lbButton type="warning" icon="daochu" @click="exportFile" v-preventReClick
            v-if="isShowBtn('ffd3fc7925dd4146ba91c54b')">导出</lbButton>
        </div>
      </template>
      <!-- 主要内容 -->
      <div>
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
          @current-change="currentChange">
          <template slot-scope="{row}" slot="treatment_method">
            {{ getWayName('TreatmentMethod', row.treatment_method) }}
          </template>
          <template slot-scope="{row}" slot="phone">
            {{ row.phone ? row.phone : '/' }}
          </template>
          <template slot-scope="{row}" slot="create_time">
            {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="succes" icon="chakan" hint="查看" @click="lookExceptionDetails(row._id)"></lbButton>
          </template>
        </avue-crud> -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
          tooltip-effect="dark" style="width: 100%" border stripe>
          <el-table-column prop="parking_name" label="停车场"></el-table-column>
          <el-table-column prop="parking_space_name" label="车位编号" width="100"></el-table-column>
          <el-table-column prop="detail" label="详情说明"></el-table-column>
          <el-table-column label="处理方式" width="120">
            <template slot-scope="scope">
              {{ getWayName('TreatmentMethod', scope.row.treatment_method) }}
            </template>
          </el-table-column>
          <el-table-column prop="handler" label="处理人" width="100"></el-table-column>
          <el-table-column label="手机号" width="180">
            <template slot-scope="scope">
              {{ scope.row.phone ? scope.row.phone : '/' }}
            </template>
          </el-table-column>
          <el-table-column label="处理时间" width="180">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <lbButton type="succes" icon="chakan" hint="查看"
                @click="lookExceptionDetails(scope.row._id)"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </le-card>
    <!-- 查看车辆异常处理结果 -->
    <lebo-dialog title="查看车辆异常处理结果" width="60%" :isShow="showLookExceptionDetails"
      @close="showLookExceptionDetails = false" footerSlot closeOnClickModal>
      <lookExceptionDetails v-if="showLookExceptionDetails" :id="dialogExceptionDetailsId">
      </lookExceptionDetails>
    </lebo-dialog>
  </div>
</template>

<script>
import { getAbnormalHandleList } from '@/api/administrative'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import lookExceptionDetails from './components/lookExceptionDetails/index.vue'
// import FileSaver from "file-saver";
// import XLSX from "xlsx";
export default {
  components: { lookExceptionDetails },
  data () {
    return {
      // 查询表单
      queryForm: {
        parking_id: '',
        handler: '',
        phone: '',
        plate_number: '',
        PageSize: 10,
        PageIndex: 1,
        treatment_method: null
      },
      total: 0,
      // 表格数据
      tableData: [],
      showLookExceptionDetails: false,
      dialogExceptionDetailsId: '',
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '停车场',
            prop: 'parking_name'
          }, {
            label: '车位编号',
            prop: 'parking_space_name'
          }, {
            label: '详情说明',
            prop: 'detail',
            width: 300
          }, {
            label: '处理方式',
            prop: 'treatment_method',
            slot: true
          }, {
            label: '处理人',
            prop: 'handler'
          }, {
            label: '处理时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
      console.log(this.queryForm)
    }
    this.fnGetAbnormalHandleList()
  },
  computed: {
    ...mapState(['parkingList', 'queryObj', 'queryStatus']),
    ...mapGetters(['getOptions', 'getWayName'])
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    // this.requestPorkingList();
    // this.requestFindList();
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    ...mapActions(['requestPorkingList', 'requestFindList']),
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    async fnGetAbnormalHandleList () {
      const res = await getAbnormalHandleList({
        parking_id: this.queryForm.parking_id,
        handler: this.queryForm.handler,
        phone: this.queryForm.phone,
        plate_number: this.queryForm.plate_number,
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        treatment_method: this.queryForm.treatment_method ? this.queryForm.treatment_method : 0
      })
      // console.log(res.Data.DataList);
      if (res && res.Code === 200) {
        this.total = res.Data.TotalCount
        this.tableData = res.Data.DataList
      } else {
        this.total = 0
        this.tableData = []
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      // console.log(this.tableData);
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetAbnormalHandleList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetAbnormalHandleList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetAbnormalHandleList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      // console.log(`当前页: ${val}`);
      this.fnGetAbnormalHandleList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetAbnormalHandleList()
    // },
    // 导出
    async exportFile () {
      const res = await getAbnormalHandleList({
        parking_id: this.queryForm.parking_id,
        handler: this.queryForm.handler,
        phone: this.queryForm.phone,
        plate_number: this.queryForm.plate_number,
        PageIndex: 0,
        PageSize: 0
      })
      // console.log(res.Data.DataList);
      const resultList = res.Data.DataList
      if (resultList.length <= 0) {
        this.$msg.warning('暂无数据！')
      } else {
        // 设置当前日期
        var name = this.$moment(new Date()).format('YYYY-MM-DD')
        // console.log(name);
        import('@/utils/Export2Excel').then((excel) => {
          const tHeader = [
            '停车场',
            '车位编号',
            '详情说明',
            '处理方式',
            '处理人',
            '手机号',
            '处理时间'
          ]
          const filterVal = [
            'parking_name',
            'parking_space_name',
            'detail',
            'treatment_method',
            'handler',
            'phone',
            'create_time'
          ]
          const list = resultList
          const data = this.formatJson(filterVal, list)
          excel.export_json_to_excel({
            header: tHeader, //  必填   导出数据的表头
            data, //  必填   导出具体数据
            filename: '异常处理' + name, //  非必填   导出文件名
            autoWidth: true, //  非必填   单元格是否自动适应宽度
            bookType: 'xlsx' //  非必填   导出文件类型
          })
        })
      }
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        var map = {}
        this.$set(map, j, v[j])
        return this.formatter(map, { property: j })
        // 为formatter专门为了码表渲染的组件
      })
      )
    },
    // 数据格式化
    formatter (row, column) {
      // console.log(row);
      // console.log(column);
      // 日期处理
      if (column.property === 'create_time') {
        return this.$moment(row[column.property]).format('YYYY-MM-DD HH:mm:ss')
      } else if (column.property === 'treatment_method') {
        return row.treatment_method = this.getWayName('TreatmentMethod', row.treatment_method)
      } else {
        return row[column.property]
      }
    },
    lookExceptionDetails (id) {
      this.dialogExceptionDetailsId = id
      this.showLookExceptionDetails = true
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-input.el-input--small{
  width: 190px!important;
}
</style>
